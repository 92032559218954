<template>
  <div>
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card  v-if="showPreview">
        <v-card-title>{{$t("view.PageBackendStudent.add_new_title")}} ({{$t("view.PageBackendStudent.step")}} 2/2)</v-card-title>
        <v-card-text>
      <Preview
      @close="back"
              @back="back"
              :students="students"
              :callbackSelectedStudent="setStudents"
              :callbackSuccess="callbackSuccess"
      />
    </v-card-text>
      
      </v-card>
      <v-card v-else>
        <v-card-title>{{$t("view.PageBackendStudent.add_new_title")}} ({{$t("view.PageBackendStudent.step")}} 1/2)</v-card-title>
        <v-card-text>
          <FormTextarea :seletedStudents="students" :callbackSelect="getStudents" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)">  {{$t("action.cancel")}} </v-btn>
          <v-btn color="primary" @click="validate">  {{$t("action.next")}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormTextarea from "@/components/Bread/Student/TextareaFieldStudentV2";
import Preview from "@/components/Bread/Student/ImportPreviewV2";
export default {
  props: ["callbackSuccess","url"],
  components: {
    FormTextarea,
    Preview,
  },
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    showPreview: false,
    students: [],
    name: null,
    gender: null,
  }),
  created() {
    this.api.url = this.url
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.callbackSuccess();
    };
  },
  methods: {
    getStudents(val) {
      const male = ["male","m","l","lelaki","男"];
      const female = ["female","f", "p","perempuan","女"];
      const races = ["chinese","malay", "indian"];
      this.students = val;
      
      for (var i = 0; i < this.students.length; i++) {
        if (this.students[i].name == "") {
          this.students.splice(i, 1);
          i--
        } else {
          if( this.students[i].originalRace){
            if(races.includes(this.students[i].originalRace.toLowerCase().replace(/\s/g, ''))){
              this.students[i].race = this.students[i].originalRace.toLowerCase()
            }else{
              this.students[i].race = 'other'
            }
          }
          if (male.includes(this.students[i].originalGender.toLowerCase().replace(/\s/g, ''))) {
            this.students[i].gender = "male";
          } else if (female.includes(this.students[i].originalGender.toLowerCase().replace(/\s/g, ''))) {
            this.students[i].gender = "female";
          }else{
            this.students[i].gender = "other";
          }
        }
      }
    },
    validate() {
      if (this.students[0]) {
        this.next();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    next() {
      this.showPreview = true;
    },
    cancel() {
      this.showPreview = false;
    },
    submit() {
      this.api.params = this.$_.clone({names:this.students});
      this.$api.fetch(this.api);
    },
    setStudents(students){
      this.students = students
      this.cancel()
    }
  },
};
</script>

<style>
</style>