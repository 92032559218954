<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
  <!-- EOC -->
  <!-- BOC:[form] -->
  <!-- <BreadForm
    :role="role"
    :model="model"
    :url="`${$api.servers.backend}/v1/classroom/${$route.params.parentId}/student/add`"
    action="add"
    :callbackSuccess="callbackSuccess"
  ></BreadForm> -->
  <!-- EOC -->
<PageImport :callbackSuccess="callbackSuccess" />
</v-container>
</template>

<script>
  //BOC:[model]
  import modelGrandParent from '@/models/items/institution'
  import modelParent from '@/models/items/classroom'
  import model from '@/models/items/student'
  //EOC
 import PageImport from "@/components/Bread/Student/PageImportV2";
  import { mapState } from 'vuex'
  export default {
    components:{
      PageImport,
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
      'parent',
      'grandParent'
    ],
    data: () => ({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[model]
      modelGrandParent: modelGrandParent,
      modelParent: modelParent,
      model: model,
      //EOC
      //BOC:[role]
      role:'Moderator',
      //EOC
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
       //
       this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelGrandParent.name.plural.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.modelGrandParent.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.grandParent && this.grandParent.name) ? this.grandParent.name : `${this.$t("model.name." + this.modelGrandParent.name.singular.toLowerCase())} ${this.$route.params.grandParentId}`,
        to:this.$_getRouteRead(this.role,this.modelGrandParent.key,this.$route.params.grandParentId,this.grandParent),
        exact:true,
      })
      let grandParentPath = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
       grandParentPath.query = {tab:'Classroom'}
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelParent.name.plural.toLowerCase()),
        to:grandParentPath,
        exact:true,
      })
        //
        this.breadcrumbs.push({
        text:(this.parent) ? this.parent.name : `${this.$t("model.name." + this.modelParent.name.singular.toLowerCase())} ${this.$route.params.parentId}`,
        to:this.$_getRouteChildRead(this.role,this.modelParent.key,this.$route.params.parentId,this.parent, this.modelGrandParent.key,this.$route.params.grandParentId,this.grandParent),
        exact:true,
      })
       //
      let path = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
      path.query = {tab:'Student'}
     
      //
      this.breadcrumbs.push({
        text:`Add ${this.$t("model.name." + this.model.name.singular.toLowerCase())}`,
        to:{name:'PageModeratorInstitutionClassroomStudentImport',params:{parentId:this.$route.params.parentId,parent:this.parent}},
        exact:true,
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      callbackSuccess() {
        let redirect = this.$_getRouteChildRead(this.role,this.modelParent.key,this.$route.params.parentId,this.myParent, this.modelGrandParent.key,this.$route.params.grandParentId,this.grandParent)
        redirect.query = {tab:this.model.key}
        this.$router.push(redirect)
      },
    }
  }
</script>