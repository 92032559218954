<template>
	<div>
		<div class="text-left mb-3">
			<b>Student Names, Gender (optional), Race (optional), and Previous Year Classroom Name (optional)</b><br />
			One student per row.
			You may copy and paste directly from Excel.<br/>
      It can be a list of mixing new students or students from previous year.
		</div>
		<div class="name-textarea">
			<v-textarea
				autofocus
				outlined
				name="input-7-1"
				placeholder="Ali                Male                  Malay                2A
Emily           Female              Chinese
Muttu                                                             "
				v-model="students"
				:rules="rules"
				@keydown="handleInput($event)"
			></v-textarea>
		</div>
	</div>
</template>

<script>
export default {
	props: ["callbackSelect", "seletedStudents"],
	data: () => ({
		students: "",
		filterStudents: [],
		name: null,
		rules: [(v) => !!v || "Please fill in the names"],
	}),
	watch: {
		students: function (val) {
			this.students = val;
			this.filterStudents = [];
			var filterStudents = [];
			this.name = val.split("\n");
			this.name?.forEach((line) => {
				const values = line.split("\t").map((v) => v.trim());

				let studentObj = {
					name: values[0] ,
					originalGender: values[1],
          originalRace: values[2],
          classroom: values[3]?this.$_.replace(values[3], /\s/g, '').toUpperCase():null
				};

				filterStudents.push(studentObj);
			});
			this.filterStudents = filterStudents;
			this.callbackSelect(this.filterStudents);
		},
	},
	methods: {
		handleInput(e) {
			if (e.key == "Tab") {
				e.preventDefault();
				let startText = this.students.slice(0, e.target.selectionStart);
				let endText = this.students.slice(e.target.selectionStart);
				this.students = `${startText}\t${endText}`;
				e.target.selectionEnd = e.target.selectionStart + 1;
			}
		},
	},
	mounted() {
		for (var i = 0; i < this.seletedStudents.length; i++) {
			let student = this.seletedStudents[i];
			this.students +=
				`${student.selectedName.name?student.selectedName.name : ""}\t${student.originalGender?student.originalGender : ""}\t${student.originalRace?student.originalRace : ""}\t${student.classroom?student.classroom : ""}\n`;
		}
	},
};
</script>

<style>
.name-textarea {
	-moz-tab-size: 24 !important; /* Firefox */
	tab-size: 24 !important;
}
</style>
